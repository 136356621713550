import * as React from "react"
import { Link, graphql, PageProps } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { FC } from "react"

const BlogPostTemplate: FC<PageProps<GatsbyTypes.BlogPostByIdQuery>> = ({ data, location }) => {
  const post = data.post;
  const siteTitle = data.site?.siteMetadata?.title || `Title`
  const { previous, next } = data;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post?.title || 'nodata'}
        description={post?.description?.childMarkdownRemark?.html || post?.description?.childMarkdownRemark?.excerpt}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline">{post?.title}</h1>
          <p>{post?.publishDate}</p>
        </header>
        <section
          dangerouslySetInnerHTML={{ __html: post?.body?.childMarkdownRemark?.html || 'nodata' }}
          itemProp="articleBody"
        />
        <hr />
        <footer>
          <Bio />
        </footer>
      </article>
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous?.slug && (
              <Link to={`/blog/${previous.slug}`} rel="prev">
                ← {previous.title}
              </Link>
            )}
          </li>
          <li>
            {next?.slug && (
              <Link to={`/blog/${next.slug}`} rel="next">
                {next.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate
export const pageQuery = graphql`
query BlogPostById($id: String!, $previousPostId: String, $nextPostId: String) {
  site {
    siteMetadata {
      title
    }
  }
  post: contentfulBlogPost(id: {eq: $id}) {
    id
    slug
    title
    publishDate(formatString: "MMMM Do, YYYY")
    body {
      childMarkdownRemark {
        html
        excerpt
      }
    }
    description {
      childMarkdownRemark {
        html
        excerpt
      }
    }
  }
  previous: contentfulBlogPost(id: {eq: $previousPostId}) {
    id
    slug
    title
    publishDate(formatString: "MMMM Do, YYYY")
    body {
      childMarkdownRemark {
        html
        excerpt
      }
    }
    description {
      childMarkdownRemark {
        html
        excerpt
      }
    }
  }
  next: contentfulBlogPost(id: {eq: $nextPostId}) {
    id
    slug
    title
    publishDate(formatString: "MMMM Do, YYYY")
    body {
      childMarkdownRemark {
        html
        excerpt
      }
    }
    description {
      childMarkdownRemark {
        html
        excerpt
      }
    }
  }
}
`